@import "./../required";

$size-sm: 25px;
$size: 30px;
$size-lg: 48px;

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size-sm;
    height: $size-sm;
    overflow: hidden;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    border-radius: 50%;

    @include media-breakpoint-up(md) {
        width: $size;
        height: $size;
    }

    &.user-default {
        color: $gray-600;
        background-color: $gray-200;
    }
}

.avatar-lg {
    width: $size-lg;
    height: $size-lg;
    font-size: $font-size-lg;
}
